import React from "react";
import {Collapse, IconButton, Navbar, Typography} from "@material-tailwind/react";
import {Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";
import {NavList} from "./NavList";
import {StaticImage} from "gatsby-plugin-image";
import { Link } from 'gatsby'

export function NavbarSimple() {
    const [openNav, setOpenNav] = React.useState(false);

    const handleWindowResize = () =>
        window.innerWidth >= 960 && setOpenNav(false);

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    return (
      <Navbar className="mx-auto px-6 py-3 bg-transparent border-none rounded-none">
            <div className="flex items-center justify-between">
                <Link to="/">
                <StaticImage
                    src={"../images/logo.png"}
                    width={175}
                    alt={"logo"}
                />
                </Link>
                <div className="hidden lg:block">
                    <NavList />
                </div>
                <IconButton
                    variant="text"
                    className="flex flex-row text-white ml-auto h-6 w-6 hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                    ripple={false}
                    onClick={() => setOpenNav(!openNav)}
                >
                    <span className="">Menu</span>
                    {openNav ? (
                          <XMarkIcon className="h-6 w-6" strokeWidth={2} />
                    ) : (
                        <Bars3Icon className="h-6 w-6" strokeWidth={2} />
                    )}
                </IconButton>
            </div>
            <Collapse open={openNav}>
                <NavList />
            </Collapse>
        </Navbar>
    );
}