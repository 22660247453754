import './src/styles/global.css'
import React from 'react'
import { AppProviders } from './src/components/app-providers'
import { NavbarSimple } from './src/components/NavBarSimple'
import { SimpleFooter } from './src/components/SimpleFooter'

export const wrapRootElement = ({ element }) => {

  return (
    <AppProviders>
      <NavbarSimple/>
      {element}
      <SimpleFooter/>
  </AppProviders>
  )
}
