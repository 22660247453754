import React from "react";
import {
    Typography,
} from "@material-tailwind/react";
import {FaInstagram, FaYoutube} from "react-icons/fa6";
import { FaGuitar, FaMusic, FaNewspaper } from 'react-icons/fa'
import {Link} from "gatsby";

export function NavList() {
    let menuItem = 'flex items-center justify-center flex-column hover:text-blue-500 transition-colors text-white uppercase';
    return (
        <ul className="my-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-medium"
            
            >
                <Link to="/concerts" className={menuItem}>
                    <FaGuitar size="20px" className="pr-1" />
                    Concerts
                </Link>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-medium"
            
            >
                <Link className={menuItem} to="/musique">
                    <FaMusic size="20px" className="pr-1" />
                    Musique
                </Link>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-medium"
            
            >
                <a href="https://www.youtube.com/@jackhammer-bzh" className={menuItem} target="_blank">
                    <FaYoutube size="20px" className="pr-1" />
                    Youtube
                </a>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-medium"
            
            >
                <a href="https://www.instagram.com/jackhammer.bzh" className={menuItem} target="_blank">
                    <FaInstagram size="20px" className="pr-1" />
                    Instagram
                </a>
            </Typography>
        </ul>
    );
}