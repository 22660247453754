import {Typography} from "@material-tailwind/react";
import React from "react";
import { FaInstagram, FaFacebook, FaYoutube, FaEnvelope } from "react-icons/fa6";

export function SimpleFooter() {
    const now = new Date().getFullYear();
    return (

        <footer
            className="max-w-screen-2xl mx-auto px-6 bg-transparent p-4 flex flex-col">
            <ul className="flex flex-row justify-around">
                <li>
                    <Typography
                        variant="small"
                        as="a"
                        target="_blank"
                        href="https://www.facebook.com/j.hammer.band"
                        className="flex flex-col items-center justify-center"
                    >
                        <FaFacebook size="40px" />
                        Facebook
                    </Typography>
                </li>
                <li>
                    <Typography
                        variant="small"
                        as="a"
                        href="https://www.instagram.com/jackhammer.bzh"
                        target="_blank"
                        className="flex flex-col items-center justify-center"
                    >
                        <FaInstagram size="40px" />
                        Instagram
                    </Typography>
                </li>
                <li>
                    <Typography
                        variant="small"
                        as="a"
                        href="https://www.youtube.com/@jackhammer-bzh"
                        target="_blank"
                        className="flex flex-col items-center justify-center"
                    >
                        <FaYoutube size="40px" />
                        Youtube
                    </Typography>
                </li>
                <li>
                    <Typography
                        variant="small"
                        as="a"
                        className="flex flex-col items-center justify-center"
                        href="mailto:&#106;&#046;&#104;&#097;&#109;&#109;&#101;&#114;&#046;&#098;&#097;&#110;&#100;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;"
                    
                    >
                        <FaEnvelope size="40px" />
                        Contact
                    </Typography>
                </li>
            </ul>
            <Typography className="my-4 flex flex-col items-center justify-center" variant="small">
                &copy; {now} - Jackhammer
            </Typography>
        </footer>
    );
}