import React from "react";
import {ThemeProvider} from "@material-tailwind/react";


// @ts-ignore
export const AppProviders = ({children}) => {
        const theme = {
            colors: {
                primary: "#2563EB",
                secondary: "#D3D3D3",
                text: "#ffffff",
                white: "#fff",
                black: "#000",
            },
            borderRadius: {
                none: "0",
                sm: "0.125rem",
                DEFAULT: "0.25rem",
                md: "0.375rem",
                lg: "0.5rem",
                xl: "0.75rem",
                "2xl": "1rem",
                "3xl": "1.5rem",
                full: "9999px",
            },
            fontFamily: {
                sans: ["Inter", "sans-serif"],
                serif: ["Merriweather", "serif"],
            },
            typography: {
                defaultProps: {
                    variant: "paragraph",
                    color: "white",
                    textGradient: false,
                    className: "",
                },
                styles: {
                    variants: {
                        paragraph: {
                            fontsize: 'text-base',
                            fontFamily: "serif",
                            lineHeight: "leading-normal",
                          padding: 'px-6',
                          margin: 'mb-4',
                          width: 'w-[60ch]',
                          maxWidth: 'max-w-full',
                        },
                        lead: {
                            width: "w-auto",
                        },
                    }
                }
            }
        };
        return <ThemeProvider value={theme}>{children}</ThemeProvider>;
    }
;